import { getDigiLifeLoanProtection } from "./index";
import { createSelector } from "reselect";

export const getThankYou = createSelector(
    getDigiLifeLoanProtection,
    digiLifeLoanProtection => digiLifeLoanProtection.thankYouDetails
);

export const getReferenceNumber = createSelector(
    getThankYou,
    thankYou => thankYou && thankYou.referenceNumber,
);

export const getFirstNames = createSelector(
    getThankYou,
    thankYou => {
        if(thankYou){
            return thankYou.firstNames
        }
    },
);