import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import OmButton from '../../components/forms/OmButton';
import {
    DLP_PAGE_INDICES,
    DLP_PAGES,
    PRODUCT_DETAIL_URL,
} from '@om/redux/actions/digiLifeLoanProtection/types';
import {
    navigateNext,
    navigatePrevious,
    setNavigationDone,
} from '@om/redux/actions/digiLifeLoanProtection/routing';
import {
    getActivePageIndex,
    getContinueButtonText,
    getDoEfpNavigation,
} from '@om/redux/selectors/digiLifeLoanProtection/routing';
import { getReplacementPolicyDisabled } from '@om/redux/selectors/easiplusFuneralPlan/replacementPolicy';
import { OmApplicationWizardFooter } from '@om/component-library-react';
import { resetReplacementPolicy } from '@om/redux/actions/easiplusFuneralPlan/replacementPolicy';

const ApplicationFooter = () => {
    const dispatch = useDispatch();

    const {
        activePageIndex,
        continueButtonText,
        doEfpNavigation,
    } = useSelector(
        createStructuredSelector({
            activePageIndex: getActivePageIndex,
            replacementPolicyDisabled: getReplacementPolicyDisabled,
            continueButtonText: getContinueButtonText,
            doEfpNavigation: getDoEfpNavigation,
        })
    );
    const location = useLocation();
    useEffect(() => {
    // if (doExitCvsNavigation) {
    //     navigate(parentAppUrl);
    //     return;
    // }

        if (!doEfpNavigation) {
            return;
        }

        const currentPageIndex = DLP_PAGES.map((page) => page.URL).indexOf(
            location.pathname
        );
        if (currentPageIndex === activePageIndex) {
            dispatch(setNavigationDone());
            return;
        }

        navigate(DLP_PAGES[activePageIndex].URL);
    }, [location, activePageIndex, dispatch, doEfpNavigation]);

    const navigatePreviousHandler = () => {
        if (
            activePageIndex === DLP_PAGE_INDICES.REPLACEMENT_POLICY 
        ) {
            dispatch(resetReplacementPolicy());
            navigate(PRODUCT_DETAIL_URL);
        } else {
            dispatch(navigatePrevious());
        }
    };

    // TODO: Implement page types for page index and remove magic number
    return (
        activePageIndex !== 7 && (
            <OmApplicationWizardFooter
                index={activePageIndex}
                totalCount={DLP_PAGES.length}
            >
                <OmButton
                    slot="left"
                    type="text"
                    size="small"
                    text="Previous"
                    disabled={false}
                    onClick={navigatePreviousHandler}
                />

                <OmButton
                    slot="right"
                    type="primary"
                    size="small"
                    text={continueButtonText}
                    //   disabled={activePageIndex === DLP_PAGE_INDICES.REPLACEMENT_POLICY}
                    onClick={() => dispatch(navigateNext())}
                />
            </OmApplicationWizardFooter>
        )
    );
};

export default ApplicationFooter;
