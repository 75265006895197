import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { graphql, navigate } from 'gatsby';
import { Router, useLocation } from '@reach/router';
import { useSessionCamVariables } from '../hooks/useSessionCamVariables';
import { getActivePageIndex } from '@om/redux/selectors/digiLifeLoanProtection/routing';
import { getRequestId } from '@om/redux/selectors/easiplusFuneralPlan/sessionCamVariables';
import { OmApplicationPage } from '@om/component-library-react';
import ApplicationLayout from '../layouts/ApplicationLayout';
import ApplicationWizardFooter from '../applications/digiLifeLoanProtection/ApplicationFooter';
import ApplicationHeader from '../applications/digiLifeLoanProtection/ApplicationHeader';
import ConfirmDetailsPage from '../applications/digiLifeLoanProtection/confirmDetailsPage/ConfirmDetailsPage';
import PaymentDetailsPage from '../applications/digiLifeLoanProtection/paymentDetailsPage/PaymentDetailsPage';
import PersonalDetailsPage from '../applications/digiLifeLoanProtection/personalDetailsPage/PersonalDetailsPage';
import ReplacementPolicyPage from '../applications/digiLifeLoanProtection/replacementPolicyPage/ReplacementPolicyPage';
import { getApplicationReferenceData } from '@om/redux/actions/digiLifeLoanProtection/referenceData';
import {
    DLP_PAGES,
    DLP_PAGE_URLS,
    PRODUCT_DETAIL_URL,
    APP_NOT_INITIATED,
} from '@om/redux/actions/digiLifeLoanProtection/types';
import UploadDocumentsPage from '../applications/digiLifeLoanProtection/UploadDocumentsPage/UploadDocumentsPage';
import ThankYouPage from '../applications/digiLifeLoanProtection/thankYouPage/ThankYouPage';
import { resetScroll } from '@om/redux/actions/digiLifeLoanProtection/scrolling';
import { getScrollToId } from '@om/redux/selectors/digiLifeLoanProtection/scrolling';

const DlpApplicationPage = ({ data }) => {
    const [isRouteCorrect, setRouteCorrect] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    const {
        include_avs,
        include_cdv,
        thank_you,
    } = data.contentstackDigilifeLoanProtectionApplication;

    const { activePageIndex,scrollToId, requestId } = useSelector(
        createStructuredSelector({
            activePageIndex: getActivePageIndex,
            scrollToId: getScrollToId,
            requestId: getRequestId,
        })
    );


    useEffect(() => {
        dispatch(getApplicationReferenceData());
    }, [dispatch]);

    useEffect(() => {
        const currentPageIndex = DLP_PAGES.map((page) => page.URL).indexOf(
            location.pathname
        );
        if (currentPageIndex !== activePageIndex) {
            if (activePageIndex === APP_NOT_INITIATED) {
                navigate(PRODUCT_DETAIL_URL);
            } else {
                navigate(DLP_PAGES[activePageIndex].URL);
            }
        } else {
            setRouteCorrect(true);
        }
    }, [activePageIndex, location.pathname, dispatch]);

    useSessionCamVariables(requestId);

    if (!isRouteCorrect) return <></>;
    return (
        <ApplicationLayout>
            <OmApplicationPage
                onScrollEnd={() => {
                    dispatch(resetScroll());
                }}
                scrollToId={scrollToId}
            >
                <div slot="header">
                    <ApplicationHeader
                        mobileHeaderTexture={false}
                    />
                </div>

                <div slot="content-no-sidebar">
                    <Router>
                        <ReplacementPolicyPage
                            path={DLP_PAGE_URLS.REPLACEMENT_POLICY}
                            excludeConfirmDetailsPage={false}
                        />
                        <PersonalDetailsPage path={DLP_PAGE_URLS.PERSONAL_DETAILS} />
                        <PaymentDetailsPage
                            path={DLP_PAGE_URLS.PAYMENT_DETAILS}
                            includeAvs={include_avs}
                            includeCdv={include_cdv}
                        />
                        <UploadDocumentsPage path={DLP_PAGE_URLS.UPLOAD_DOCUMENTS} />
                        <ConfirmDetailsPage path={DLP_PAGE_URLS.CONFIRMATION} />
                        <ConfirmDetailsPage path={DLP_PAGE_URLS.CONFIRMATION} />
                        <ThankYouPage
                            path={DLP_PAGE_URLS.THANK_YOU}
                            thankYouEntry={thank_you}
                        />
                    </Router>
                </div>

                <div slot="footer">
                    {activePageIndex !== 5 && <ApplicationWizardFooter />}
                </div>
            </OmApplicationPage>
        </ApplicationLayout>
    );
};

export const pageQuery = graphql`
  query ModularDlpApplicationPageQuery($id: String!) {
    contentstackDigilifeLoanProtectionApplication(id: { eq: $id }) {
      uid
      title
      mobile_header_texture {
        ...File
      }
      thank_you {
        ...ThankYou
      }
      call_me_back_replacement {
        ...CallMeBack
      }
      call_me_back_generic {
        ...CallMeBack
      }
      include_avs
      include_cdv
    }
  }
`;
export default DlpApplicationPage;
