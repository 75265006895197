import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {graphql} from 'gatsby';
import {resetPersonalDetails} from '@om/redux/actions/digiLifeLoanProtection/personalDetails';

import {resetPaymentDetails} from '@om/redux/actions/digiLifeLoanProtection/paymentDetails';
import {resetConfirmDetails} from '@om/redux/actions/digiLifeLoanProtection/confirmDetails';
import {resetScroll} from '@om/redux/actions/digiLifeLoanProtection/scrolling';
import {resetRouting} from '@om/redux/actions/digiLifeLoanProtection/routing';
import {
    OmApplicationThankYouPage,
    OmApplicationThankYou
} from '@om/component-library-react';
import tick_icon from '../assets/icons/tick.png';
import { getReferenceNumber ,getFirstNames} from '@om/redux/selectors/digiLifeLoanProtection/thankYou';


const ThankYouPage = ({
    thankYouEntry,
   
}) => {
    const dispatch = useDispatch();
    const {
        applicationReferenceNumber,
        firstName,
    } = useSelector(
        createStructuredSelector({
            applicationReferenceNumber: getReferenceNumber,
            firstName: getFirstNames
        })
    );
    useEffect(() => {
        // reset the application when landing on thank you page
        dispatch(resetPersonalDetails());
        dispatch(resetPaymentDetails());
        dispatch(resetConfirmDetails());
        dispatch(resetScroll());
        dispatch(resetRouting());

    });

    if (!thankYouEntry) {
        return '';
    }

    const {
        close_button_link,
        caption,
        contact_caption,
    } = thankYouEntry[0];
    return (
        <OmApplicationThankYouPage>
            <OmApplicationThankYou
                slot="thank-you"
                closeButtonLink={
                    close_button_link && close_button_link.href
                        ? close_button_link.href
                        :
                        ''
                }
                imageLink={tick_icon}
                personName={firstName}
                thankYouCaption={caption}
                referenceNumber={applicationReferenceNumber}
                contactCaption={contact_caption}
                // buttonName={button ? button.title : ''}
                // buttonLink={'/personal/digilife-loan-protection/'}
            />

          
        </OmApplicationThankYouPage>
    );
};

export const pageQuery = graphql`
    fragment ThankYou on Contentstack_thank_you {
        close_button_link {
            ...Link
        }
        success_image_link {
            ...Link
        }
        caption
        contact_caption
        button {
            title
            ...Link
        }

    }
`;

export default ThankYouPage;
