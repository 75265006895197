import { createSelector } from "reselect";
import { getDigiLifeLoanProtection } from "./index";

export const getScrolling = createSelector(
    getDigiLifeLoanProtection,
    digiLifeLoanProtection => digiLifeLoanProtection.scrolling
);

export const getScrollToClass = createSelector(
    getScrolling,
    scrolling => scrolling.scrollToClass,
)

export const getScrollToId = createSelector(
    getScrolling,
    scrolling => scrolling.scrollToId,
)
export const getScrollToBeId = createSelector(
    getScrolling,
    scrolling => scrolling.scrollToBeId,
)