import React from 'react';
import OmButton from '../../../../components/forms/OmButton';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';

const ErrorResponseModal = ({ slot, open, buttonHandler }) => {
    return (
        <OmApplicationPopupModal
            slot={ slot }
            name="ErrorResponseModal"
            exitable={false}
            open={open}
        >
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt94f2cb596218bd62/5f2935c8ee8504335e705005/WRONG_CMOS.svg" />
                <h5 className="no-bottom-margin">Something Went Wrong</h5>
                <h6 className="no-bottom-margin account-verification-text">Please try again letter</h6>
            </div>
            <OmButton
                slot="button"
                type='primary'
                size='large'
                text='Try again'
                onClick={() => buttonHandler()}
            />
        </OmApplicationPopupModal>
    );
};

export default ErrorResponseModal;