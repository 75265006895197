import React, { useRef } from 'react';
import {
    OmdsFileUploadFile,
    OmdsIcon,
} from '@mom-omds/react-design-system-components/dist/generated/components';
import {
    OmApplicationSummaryDetailsCard,
    OmFormError,
    OmIcon,
} from '@om/component-library-react';
import file_icon_jpeg from '../assets/icons/File_Icon_JPEG.svg';
import file_icon_jpg from '../assets/icons/File_Icon_JPG.svg';
import file_icon_pdf from '../assets/icons/File_Icon_PDF.svg';
import file_icon_png from '../assets/icons/File_Icon_PNG.svg';
import file_icon_tif from '../assets/icons/File_Icon_TIF.svg';
import file_icon_tiff from '../assets/icons/File_Icon_TIFF.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCopyOfIDDocument, getDocumentsForm } from '@om/redux/selectors/digiLifeLoanProtection/uploadDocuments';
import { setAddressProofDocumentsValue, setIdDocumentsValue, setIncomeProofDocumentsValue, setbankStatementDocumentsValue, setloanLetterDocumentsValue } from '@om/redux/actions/digiLifeLoanProtection/uploadDocuments';
import { getPersonalDetailsForm } from '@om/redux/selectors/digiLifeLoanProtection/personalDetails';
const fileTypeIcons = {
    jpg: file_icon_jpg,
    jpeg: file_icon_jpeg,
    pdf: file_icon_pdf,
    tif: file_icon_tif,
    tiff: file_icon_tiff,
    png: file_icon_png,
};

export const formatFileSize = (bytes) => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(4)) + ' ' + sizes[i];
};

const getFileStatusText = (file) => {
    if (file) {
        return formatFileSize(file?.size);
    }
    return `Max 2MB  (jpeg, png or pdf)`;
};

const UploadDocumentsPage = () => {
    // let memberSignature =null
    const copyOfCertifiedIdInputFile = useRef(null);
    const incomeProofInputFile = useRef(null);
    const addressProofInputFile = useRef(null);
    const loanLetterInputFile = useRef(null);
    const bankStatementInputFile = useRef(null);
    const dispatch=useDispatch()
    const {
        copyOfCertifiedId,
        documentsForm:{incomeProof,addressProof,loanLetter,bankStatement},
        personalDetailsForm:{employmentType}
    } = useSelector(
        createStructuredSelector({
            copyOfCertifiedId: getCopyOfIDDocument,
            documentsForm: getDocumentsForm,
            personalDetailsForm: getPersonalDetailsForm,
        })
    );
    const handleFileInput = (event, inputType) => {
        const file = event?.target?.files[0];
        if (file) {
            const filename = file?.name;
            const fileType = file?.type;
            // Encode the file data as a base64 string
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileData = reader.result;
                const fileSize = file.size;
                const fileContaint = {data:fileData, size: fileSize, type:fileType }

                // Now, you can store the file content (string) in localStorage using your custom hook.
                switch(inputType){
                case 'copyOfCertifiedId':
                    return dispatch(setIdDocumentsValue(filename,fileContaint,file))
                case 'incomeProof':
                    return dispatch(setIncomeProofDocumentsValue(filename,fileContaint,file))
                case 'addressProof':
                    return dispatch(setAddressProofDocumentsValue(filename,fileContaint,file))
                case 'loanLetter':
                    return dispatch(setloanLetterDocumentsValue(filename,fileContaint,file))
                case 'bankStatement':
                    return dispatch(setbankStatementDocumentsValue(filename,fileContaint,file))
                default:
                    return null
                }
            };
        }
    };
    const removeFile = (inputType) => {
        switch(inputType){
        case 'copyOfCertifiedId':
            return dispatch(setIdDocumentsValue(null,null,null))
        case 'incomeProof':
            return dispatch(setIncomeProofDocumentsValue(null,null,null))
        case 'addressProof':
            return dispatch(setAddressProofDocumentsValue(null,null,null))
        case 'loanLetter':
            return dispatch(setloanLetterDocumentsValue(null,null,null))
        case 'bankStatement':
            return dispatch(setbankStatementDocumentsValue(null,null,null))
        default:
            return null
        }
    };
    const UploadArray = [
        {
            title: 'CERTIFIED COPY OF ID*',
            key: 'copyOfCertifiedId',
            status: '',
            ref:copyOfCertifiedIdInputFile,
            selectedFile: copyOfCertifiedId,
            imageUrl:  fileTypeIcons[
                copyOfCertifiedId?.fileName?.split('.').slice(-1)
            ],
        },
        {
            title: 'PROOF OF INCOME*',
            key: 'incomeProof',
            status: '',
            ref:incomeProofInputFile,
            selectedFile: incomeProof,
            imageUrl:  fileTypeIcons[
                incomeProof.fileName?.split('.').slice(-1)
            ],
        },
        {
            title: 'PROOF OF ADDRESS*',
            key: 'addressProof',
            status: '',
            ref:addressProofInputFile,
            selectedFile: addressProof,
            imageUrl: fileTypeIcons[
                addressProof.fileName?.split('.').slice(-1)
            ],
        },
        {
            title: 'LOAN LETTER*',
            key: 'loanLetter',
            status: '',
            ref:loanLetterInputFile,
            selectedFile: loanLetter,
            imageUrl:  fileTypeIcons[
                loanLetter.fileName?.split('.').slice(-1)
            ],
        },
        {
            title: 'CONFIRMATION OF BANK ACCOUNT*',
            key: 'bankStatement',
            status: '',
            ref:bankStatementInputFile,
            selectedFile: bankStatement,
            imageUrl:  fileTypeIcons[
                bankStatement.fileName?.split('.').slice(-1)
            ],
        },
    ];
    return (
        <div className="family-member-card-group-wrapper">
            <div slot="family-member-card-items">
                <div
                    className="family-member-card-group-wrapper"
                    style={{ width: '50rem', margin: 'auto' }}
                >
                    <OmApplicationSummaryDetailsCard>
                        <div slot="content">
                            <h5 className="small upload-heading no-bottom-margin">
                Upload Documents{' '}
                            </h5>
                            <span className="mobile-line-break">
                                <br />
                            </span>
                            <hr></hr>
                            {
                                UploadArray.flatMap(({title,key,status,imageUrl,selectedFile,ref})=>employmentType.value==='02'&&key=== 'incomeProof'?[]:<>
                                    <span className="mobile-line-break">
                                        <br />
                                    </span>
                                    <input
                                        type="file"
                                        accept={`.pdf, image/png, image/jpg, image/jpeg`}
                                        onClick={(event) => {
                                            event.target.value = '';
                                        }}
                                        onInput={(e) => handleFileInput(e, key)}
                                        className="omds-hidden"
                                        ref={ref}
                                    />
                                    <OmdsFileUploadFile
                                        // hasError={!!selectedFile.error}
                                        onRemove={() => removeFile(key)}
                                        onRetryUploading={(e) =>
                                            handleFileInput(e, key)
                                        }
                                        onFileClick={() =>
                                            ref?.current?.click()
                                        }
                                        className="file-uploader-file"
                                    >
                                        {selectedFile?.fileContaint !== null && (
                                            <OmIcon
                                                className="file-upload-type-icon"
                                                slot="leading-icon"
                                                height={10}
                                                width={10}
                                                imageUrl={imageUrl}
                                            />
                                        )}
                                        {title}
                                        <br/>
                                        {selectedFile?.fileName??''}
                                        <div slot="status">
                                            {getFileStatusText(selectedFile?.fileContaint)}
                                        </div>
                                        {/* {selectedFile?.fileContaint === null && ( */}
                                        <OmdsIcon
                                            slot="suffix-icon"
                                            size={24}
                                            name="file_upload"
                                            className="file-upload-icon"
                                        ></OmdsIcon>
                                        {/* )} */}
                                        <div slot="hint-text">
                                            <OmFormError
                                                message={
                                                    selectedFile?.error 
                                                }
                                            />
                                        </div>
                                    </OmdsFileUploadFile>
                                </>) 
                            }
                        </div>
                    </OmApplicationSummaryDetailsCard>
                </div>
            </div>
        </div>
    );
};

export default UploadDocumentsPage;
