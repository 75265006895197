import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    OmFormInputFieldWrapper,
    OmFormDropdownFieldWrapper,
    OmEfpPersonalDetails,
    OmApplicationReviewSection,
    OmLabeledValueSummary,
} from '@om/component-library-react';

import {
    setCityValue,
    setCountryValue,
    setEmailValue,
    setIdValue,
    setPostalCityValue,
    setPostalStreetAddressValue,
    setStreetAddressValue,
    setTitleValue,
    setFirstNamesValue,
    setSurnameValue,
    setMobileValue,
    setGenderValue,
    setIdExpiryValue,
    setEducationValue,
    setDistrictValue,
    setLoanTypeValue,
    setBankValue,
    setLoanTermValue,
    setIncomeSourceValue,
    setOccupationValue,
    setPaymentFrequencyValue,
    setEmploymentTypeValue,
    setPostalCountryValue,
    setIdTypeValue,
    setPostalDistrictValue,
} from '@om/redux/actions/digiLifeLoanProtection/personalDetails';
import {
    getGender,
    getDateOfBirth,
    getEducation,
    getLoanType,
    getLoanAmount,
    getLoanTerm,
    getCashBack,
    getPayment,
    getSalary
} from '@om/redux/selectors/roaQuoteTools/digiLifeQuoteTool';
import {
    getCity,
    getCountry,
    getEmail,
    getFirstNames,
    getId,
    getMobile,
    getPostalCity,
    getPostalCountry,
    getPostalStreetAddress,
    getStreetAddress,
    getSurname,
    getTitle,
    // getLoanAmount,
    // getGender,
    // getEducation,
    getDistrict,
    getBank,
    // getLoanTerm,
    // getLoanType,
    getEmploymentType,
    getSourceOfIncome,
    getOccupation,
    getIdExpiry,
    getIdType,
    getPostalDistrict,
    getSourceOfIncomeText
} from '@om/redux/selectors/digiLifeLoanProtection/personalDetails';
// import {getGender}from "@om/redux/selectors/roaQuoteTools/digiLifeQuoteTool";
import {
    getReferenceTitles,
    getReferenceEducationEducation,
    getReferenceLoanType,
    getReferenceBanks,
    getReferenceEmploymentType,
    getReferenceSourceOfIncome,
    getReferenceGenders,
    getReferencePaymentFrequency,
    getReferenceNationalities,
    getReferenceDistricts,
    getReferenceSalaryRange,
} from '@om/redux/selectors/digiLifeLoanProtection/referenceData';

import { DEFAULT_VALIDATION_DELAY } from '../../config';
import { setLoanAmountValue } from '@om/redux/actions/digiLifeLoanProtection/personalDetails';
import ApplicationSummaryDetailsCard from '../../../components/applications/ApplicationSummaryDetailsCard';
import { getScrollToId } from '@om/redux/selectors/digiLifeLoanProtection/scrolling';
import { DLP_PERSONAL_DETAILS_SCROLL_ID } from '@om/redux/middleware/rulesEngine/status';
import { transitionScrolling } from '@om/redux/actions/digiLifeLoanProtection/scrolling';
import { resetUploadDocuments } from '@om/redux/actions/digiLifeLoanProtection/uploadDocuments';
import { setIncomeSourceTextValue } from '@om/redux/actions/digiLifeLoanProtection/personalDetails';

const PersonalDetailsPage = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        const selectedGender= referenceGender.flatMap(({code,description})=>gender?.value===code?{value:description,id:code}:[])[0]
        const selectedEducation= referenceEducation.flatMap(({code,description})=>education?.value===code?{value:description,id:code}:[])[0]
        const selectedLoanType= referenceLoanType.flatMap(({code,description})=>loanType?.value===code?{value:description,id:code}:[])[0]
        const selectedPaymentFrequency= referencePaymentFrequency.flatMap(({code,description})=>paymentFrequency?.value===code?{value:description,id:code}:[])[0]
        // const selectedDateOfBirth= referenceEducation.flatMap(({code,description})=>education?.value==code?{value:description,id:code}:[])[0]
        const selectedLoanAmount= loanAmount?.value
        const selectedLoanTerm= loanTerm?.value
        dispatch(setGenderValue(selectedGender))
        dispatch(setEducationValue(selectedEducation))
        dispatch(setLoanAmountValue(selectedLoanAmount))
        dispatch(setLoanTermValue(selectedLoanTerm))
        dispatch(setLoanTypeValue(selectedLoanType))
        dispatch(setPaymentFrequencyValue(selectedPaymentFrequency))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch])
    const formFieldProperties = {
        required: true,
        size: 'full',
        formId: 'personalDetailsForm',
        align: 'left',
        validationDelay: DEFAULT_VALIDATION_DELAY,
    };

    const dropdownFieldProperties = {
        useNativeMobileDropdown: true,
        required: true,
        formId: 'personalDetailsForm',
        align: 'left',
    };
    const {
        city,
        country,
        email,
        firstNames,
        id,
        idExpiry,
        mobile,
        postalCity,
        postalStreetAddress,
        streetAddress,
        surname,
        title,
        referenceTitles,
        referenceNationalities,
        referenceGender,
        gender,
        referenceEducation,
        education,
        loanAmount,
        referenceLoanType,
        loanType,
        district,
        bank,
        referenceBank,
        loanTerm,
        referenceEmploymentType,
        employmentType,
        referenceSourceOfIncome,
        sourceOfIncome,
        paymentFrequency,
        occupation,
        referencePaymentFrequency,
        postalCountry,
        dateOfBirth,
        cashBack,
        idType,
        referenceDistricts,
        postalDistrict,
        scrollToId,
        referenceSalaryRange,
        salary,
        sourceOfIncomeText
    } = useSelector(
        createStructuredSelector({
            scrollToId:getScrollToId,
            city: getCity,
            country: getCountry,
            district: getDistrict,
            email: getEmail,
            firstNames: getFirstNames,
            dateOfBirth: getDateOfBirth,
            id: getId,
            idExpiry: getIdExpiry,
            mobile: getMobile,
            postalCity: getPostalCity,
            postalCountry: getPostalCountry,
            postalStreetAddress: getPostalStreetAddress,
            streetAddress: getStreetAddress,
            surname: getSurname,
            title: getTitle,
            referenceTitles: getReferenceTitles,
            referenceGender: getReferenceGenders,
            gender: getGender,
            referenceEducation: getReferenceEducationEducation,
            education: getEducation,
            loanAmount: getLoanAmount,
            referenceLoanType: getReferenceLoanType,
            loanType: getLoanType,
            bank: getBank,
            referenceBank: getReferenceBanks,
            loanTerm: getLoanTerm,
            referenceEmploymentType: getReferenceEmploymentType,
            employmentType: getEmploymentType,
            referenceSourceOfIncome: getReferenceSourceOfIncome,
            sourceOfIncome: getSourceOfIncome,
            paymentFrequency: getPayment,
            occupation: getOccupation,
            referencePaymentFrequency: getReferencePaymentFrequency,
            referenceNationalities: getReferenceNationalities,
            cashBack:getCashBack,
            idType:getIdType,
            referenceDistricts:getReferenceDistricts,
            postalDistrict:getPostalDistrict,
            referenceSalaryRange:getReferenceSalaryRange,
            salary:getSalary,
            sourceOfIncomeText:getSourceOfIncomeText
        })
    ); 
    // const updatePostalAddressMethod = (eventDetail) => {
    //   if (eventDetail.id !== postalAddressMethod.value)
    //     dispatch(setPostalAddressMethodValue(eventDetail.id));
    // };
    useEffect(()=>{
        if (scrollToId&&DLP_PERSONAL_DETAILS_SCROLL_ID.includes(scrollToId)){
            setTimeout(()=>{
                dispatch(transitionScrolling.scrollToId(scrollToId))
            },100)
        }
    
    },[dispatch])

    const referenceIdType = [
        {  description: 'Omang', id: '01' },
        {  description: 'Passport', id: '02' },
    ];
    return (<>
        <OmEfpPersonalDetails>
            <h4 slot="heading" className="heading">
        To start the application process please provide your details{' '}
            </h4>

            <div slot="form">
                <section className="form-section">
                    <h5 className="no-bottom-margin">Personal details</h5>

                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="title"
                        name="title"
                        placeholder="Title*"
                        value={title?.value}
                        errorMessagePopupAlignment="left"
                        errorMessage={title?.error}
                        onOnSelect={(event) => dispatch(setTitleValue(event.detail))}
                        options={
                            referenceTitles &&
              JSON.stringify(
                  referenceTitles.map((referenceTitle) => ({
                      value: referenceTitle.code,
                      label: referenceTitle.description,
                  }))
              )
                        }
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="firstNames"
                        name="name"
                        placeholder="First name(s)*"
                        value={firstNames.displayValue}
                        state={firstNames.status}
                        errorMessage={firstNames?.error}
                        onPersist={(event) => {
                            dispatch(setFirstNamesValue(event.detail));
                            // dispatch(thankYouSetFirstNamesValue(event.detail));
                        }}
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="surname"
                        name="surname"
                        placeholder="Surname*"
                        value={surname.displayValue}
                        state={surname?.status}
                        errorMessage={surname?.error}
                        onPersist={(event) => dispatch(setSurnameValue(event.detail))}
                    />
          
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="gender"
                        name="gender"
                        placeholder="Gender"
                        disabled='true'
                        value={gender?.value}
                        errorMessagePopupAlignment="left"
                        errorMessage={gender?.error}
                        onOnSelect={(event) => dispatch(setGenderValue(event.detail))}
                        options={
                            referenceGender &&
              JSON.stringify(
                  referenceGender.map((referenceGender) => ({
                      value: referenceGender.code,
                      label: referenceGender.description,
                  }))
              )
                        }
                    />
          
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="DOB"
                        placeholder="Date of birth"
                        name="DOB"
                        keyboardMode="numeric"
                        value={dateOfBirth?.value}
                        state={'disabled'}
                    />

                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="idType"
                        name="idType"
                        placeholder="ID type*"
                        value={idType?.displayValue}
                        errorMessagePopupAlignment="left"
                        onOnSelect={(event) =>{ dispatch(setIdTypeValue(event.detail))
                            dispatch(setIdValue(id.displayValue))
                        }}
                        options={
                            referenceIdType &&
              JSON.stringify(
                  referenceIdType.map((type) => ({
                      value: type.id,
                      label: type.description,
                  }))
              )
                        }
                    />
         
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="id"
                        placeholder={`Enter ${idType?.displayValue} number*`}
                        name="id"
                        keyboardMode="numeric"
                        value={id?.displayValue}
                        state={id?.status}
                        errorMessage={id?.error}
                        onPersist={(event) => dispatch(setIdValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="idExpiry"
                        placeholder="Enter ID expiry*"
                        name="idExpiry"
                        keyboardMode="numeric"
                        value={idExpiry?.value}
                        state={idExpiry?.status}
                        hintText="Format : dd/mm/yyyy"
                        mask="date"
                        errorMessage={idExpiry?.error}
                        onPersist={(event) => dispatch(setIdExpiryValue(event.detail))}
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="education"
                        name="education"
                        placeholder="Education*"
                        value={education?.value}
                        disabled="true"
                        errorMessagePopupAlignment="left"
                        errorMessage={education?.error}
                        onOnSelect={(event) => dispatch(setEducationValue(event.detail))}
                        options={
                            referenceEducation &&
              JSON.stringify(
                  referenceEducation.map((education) => ({
                      value: education.code,
                      label: education.description,
                  }))
              )
                        }
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="salary"
                        name="salary"
                        placeholder="Salary Range"
                        value={salary.value}
                        disabled="true"
                        errorMessagePopupAlignment="left"
                        options={
                            referenceSalaryRange &&
              JSON.stringify(
                  referenceSalaryRange.map((salaryRange) => ({
                      value: salaryRange.code,
                      label: salaryRange.description,
                  }))
              )
                        }
                    />

                    <h5 className="no-bottom-margin">Contact details</h5>

                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="email"
                        placeholder="Email*"
                        name="email"
                        value={email.displayValue}
                        state={email?.status}
                        errorMessage={email?.error}
                        onPersist={(event) => dispatch(setEmailValue(event.detail))}
                    />
                    <div className="mobile-field">
                        <OmFormInputFieldWrapper
                            {...formFieldProperties}
                            isSensitive
                            id="mobileNumber"
                            placeholder="Contact Number*"
                            name="mobileNumber"
                            value={mobile?.displayValue}
                            state={mobile?.status}
                            errorMessage={mobile?.error}
                            onPersist={(event) => dispatch(setMobileValue(event.detail))}
                        />
                    </div>

                    <h5 className="no-bottom-margin">Residential address details</h5>
                    {/* <p>
            <small>
              PO BOX or Private Bag addresses will not be accepted as a
              residential address. This will result in your application being
              declined.
            </small>
          </p> */}

                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="streetAddress"
                        placeholder="Physical address*"
                        name="streetAddress"
                        value={streetAddress?.value}
                        state={streetAddress?.status}
                        errorMessage={streetAddress?.error}
                        onPersist={(event) => dispatch(setStreetAddressValue(event.detail))}
                    />

                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="city"
                        placeholder="City/Town/Village*"
                        name="city"
                        value={city?.value}
                        state={city?.status}
                        errorMessage={city?.error}
                        keyboard-mode = 'text'
                        onPersist={(event) =>{
                            event.detail !== city.value && dispatch(setCityValue(event.detail))
                        }}
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="district"
                        name="district"
                        placeholder="District*"
                        filterable="true"
                        errorMessagePopupAlignment="left"
                        value={district?.displayValue}
                        errorMessage={district?.error}
                        onOnSelect={(event) =>
                            dispatch(setDistrictValue(event.detail))
                        }
                        options={JSON.stringify(referenceDistricts.map((district)=>(
                            { value: district.code, label: district.description }))
              
                        )}
                    />

                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        size="full"
                        id="country"
                        name="country"
                        placeholder="Country"
                        filterable="true"
                        disabled="true"
                        errorMessagePopupAlignment="left"
                        value={country.value}
                        errorMessage={country?.error}
                        onOnSelect={(event) =>
                            dispatch(setCountryValue(event.detail.value))
                        }
                        options={
                            referenceNationalities &&
              JSON.stringify(
                  referenceNationalities.map((referenceNationality) => ({
                      value: referenceNationality.code,
                      label: referenceNationality.description,
                  }))
              )
                        }
                    />

                    <h5>Postal Address Details</h5>

                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="postalAddress"
                        placeholder="Postal address*"
                        name="postalAddress"
                        value={postalStreetAddress?.value}
                        state={postalStreetAddress?.status}
                        errorMessage={postalStreetAddress?.error}
                        hintText={'E.g: P O Box 123'}
                        onPersist={(event) =>
                            dispatch(setPostalStreetAddressValue(event.detail))
                        }
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        id="postalCity"
                        placeholder="City/Town/Village*"
                        name="postalCity"
                        value={postalCity.value}
                        state={postalCity?.status}
                        errorMessage={postalCity?.error}
                        onPersist={(event) => dispatch(setPostalCityValue(event.detail))}
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        size="full"
                        id="postalDistrict"
                        name="postalDistrict"
                        placeholder="District*"
                        filterable="true"
                        errorMessagePopupAlignment="left"
                        value={postalDistrict?.displayValue}
                        errorMessage={postalDistrict?.error}
                        onOnSelect={(event) =>
                            dispatch(setPostalDistrictValue(event.detail))
                        }
                        options={JSON.stringify(referenceDistricts.map((district)=>(
                            { value: district.code, label: district.description }))
              
                        )}
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        size="full"
                        id="postalCountry"
                        name="postalCountry"
                        placeholder="Country"
                        filterable="true"
                        disabled='true'
                        errorMessagePopupAlignment="left"
                        value={postalCountry?.value}
                        errorMessage={postalCountry?.error}
                        onOnSelect={(event) =>
                            dispatch(setPostalCountryValue(event.detail.value))
                        }
                        options={
                            referenceNationalities &&
              JSON.stringify(
                  referenceNationalities.map((referenceNationality) => ({
                      value: referenceNationality.code,
                      label: referenceNationality.description,
                  }))
              )
                        }
                    />
                    <h5 className="no-bottom-margin">Loan details</h5>

                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        id="loanAmount"
                        placeholder="Loan Amount"
                        name="loanAmount"
                        value={loanAmount?.value}
                        state='disabled'
                        errorMessage={loanAmount?.error}
                        disable='true'
                        // hintText=""
                        onPersist={(event) => dispatch(setLoanAmountValue(event.detail))}
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="loanType"
                        name="loanType"
                        placeholder="Type*"
                        value={referenceLoanType.filter(({code})=>code===loanType?.value)[0].description}
                        disabled="true"
                        errorMessagePopupAlignment="left"
                        errorMessage={loanType?.error}
                        onOnSelect={(event) => dispatch(setLoanTypeValue(event.detail))}
                        options={
                            referenceLoanType &&
              JSON.stringify(
                  referenceLoanType.map((referenceTitle) => ({
                      value: referenceTitle.code,
                      label: referenceTitle.description,
                  }))
              )
                        }
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="bank"
                        name="bank"
                        placeholder="Loan Provider*"
                        value={bank?.value}
                        errorMessagePopupAlignment="left"
                        errorMessage={bank?.error}
                        onOnSelect={(event) => dispatch(setBankValue(event.detail))}
                        options={
                            referenceBank &&
              JSON.stringify(
                  referenceBank.map((referenceTitle) => ({
                      value: referenceTitle.code,
                      label: referenceTitle.description,
                  }))
              )
                        }
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        id="loanTerm"
                        placeholder="Loan Term"
                        name="loanTerm"
                        value={loanTerm?.value}
                        state={'disabled'}
                        errorMessage={loanTerm?.error}
                        hintText="in months"
                        onPersist={(event) => dispatch(setLoanTermValue(event.detail))}
                    />

                    <h5 className="no-bottom-margin">Employment & Income details</h5>

                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="employmentType"
                        name="employmentType"
                        placeholder="Employment Type*"
                        value={employmentType?.value}
                        errorMessagePopupAlignment="left"
                        errorMessage={employmentType?.error}
                        onOnSelect={(event) =>{
                            dispatch(setEmploymentTypeValue(event.detail))
                            dispatch(resetUploadDocuments())
                        }
                        }
                        options={
                            referenceEmploymentType &&
              JSON.stringify(
                  referenceEmploymentType.map((referenceTitle) => ({
                      value: referenceTitle.code,
                      label: referenceTitle.description,
                  }))
              )
                        }
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        id="occupation"
                        placeholder="Occupation*"
                        name="Occupation"
                        value={occupation?.value}
                        state={occupation?.status}
                        errorMessage={occupation?.error}
                        hintText="E.g. Accountant, IT"
                        onPersist={(event) => dispatch(setOccupationValue(event.detail))}
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="sourceOfIncome"
                        name="sourceOfIncome"
                        placeholder="Source Of Income*"
                        value={sourceOfIncome?.value}
                        errorMessagePopupAlignment="left"
                        errorMessage={sourceOfIncome?.error}
                        onOnSelect={(event) =>{
                            dispatch(setIncomeSourceValue(event.detail))
                            // eslint-disable-next-line eqeqeq
                            dispatch(setIncomeSourceTextValue(event.detail.value == 'Other'?null:event.detail.value))
                        }
                        }
                        options={
                            referenceSourceOfIncome &&
              JSON.stringify(
                  referenceSourceOfIncome.map((referenceTitle) => ({
                      value: referenceTitle.code,
                      label: referenceTitle.description,
                  }))
              )
                        }
                    />
                    {sourceOfIncome.value=== '06' && <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        id="sourceOfIncomeText"
                        placeholder="Source of Income*"
                        name="sourceOfIncomeText"
                        value={sourceOfIncomeText?.value}
                        state={sourceOfIncomeText?.status}
                        errorMessage={sourceOfIncomeText?.error}
                        // hintText="E.g. Accountant, IT"
                        onPersist={(event) => dispatch(setIncomeSourceTextValue(event.detail))}
                    />}
                    <h5 className="no-bottom-margin"> Payment frequency?</h5>

                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="paymentFrequency"
                        name="paymentFrequency"
                        placeholder="Payment Frequency"
                        value={referencePaymentFrequency?.filter(({code})=>code===paymentFrequency?.value)[0]?.description}
                        errorMessagePopupAlignment="left"
                        errorMessage={''}
                        disabled='true'
                        options={
                            referencePaymentFrequency &&
              JSON.stringify(
                  referencePaymentFrequency.map((referenceTitle) => ({
                      value: referenceTitle.code,
                      label: referenceTitle.description,
                  }))
              )
                        }
                    />
                    <h5 className="no-bottom-margin">Benefits Details</h5>
                    <br/>
                    <ApplicationSummaryDetailsCard>
                        <OmApplicationReviewSection titleHeader="BENEFIT DETAILS">
                            <div slot="edit-link" style={{display:'flex',justifyContent:'space-evenly'}} >
                            </div>
                        </OmApplicationReviewSection>
                        <div slot="section-body">
                            <OmLabeledValueSummary
                                label="CASH BACK"
                                value={cashBack.value==='02'?'NO':'YES'}
                            />  
                        </div>
                    </ApplicationSummaryDetailsCard>
                </section>
            </div>
        </OmEfpPersonalDetails>

    </>
    );
};

export default PersonalDetailsPage;
