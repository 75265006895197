import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    toggleDeclarations,
    sendConfirmDetailsSubmitApplicationApiStatus,
} from '@om/redux/actions/digiLifeLoanProtection/confirmDetails';
import {
    getAccountNumber,
    getDebitOrderDay,
    getPaymentAuthorization,
    getFormattedBankName,
    getFormattedAccountName,
    getAccountHolder,
} from '@om/redux/selectors/digiLifeLoanProtection/paymentDetails';
import {
    getFirstNames,
    getSurname,
    getFullName,
    getGender,
    getId,
    getIdExpiry,
    getTitle,
    getEducation,
    getStreetAddress,
    getPostalAddressSame,
    getMobile,
    getEmail,
    getPostalAddressMethod,
    getPostalAddress,
    getResedentialStreetAddress,
    getAddressLabel,
    getFormattedContactNumber,
    getLoanAmount,
    getLoanType,
    getBank,
    getLoanTerm,
    getEmploymentType,
    getOccupation,
    getSourceOfIncome,
    getPaymentFrequency,
} from '@om/redux/selectors/digiLifeLoanProtection/personalDetails';
import {
    getDateOfBirth,
    getCashBack,
} from '@om/redux/selectors/roaQuoteTools/digiLifeQuoteTool';
import { getDigiLifePremiumAmount } from '@om/redux/selectors/roaQuoteTools/digiLifeQuoteTool';
import {
    getDeclarationsValid,
    getDeclarationsInvalid,
    getDeclarationsError,
    getSubmissionApiStatus,
} from '@om/redux/selectors/digiLifeLoanProtection/confirmDetails';
import { getIsConfirmationPage } from '@om/redux/selectors/easiplusFuneralPlan/routing';
import {
    navigateToPersonalDetails,
    navigateToPaymentDetails,
    navigateToUploadDocuments
} from '@om/redux/actions/digiLifeLoanProtection/routing';
import ApplicationSummaryDetailsCard from '../../../components/applications/ApplicationSummaryDetailsCard';
import {
    OmApplicationReviewSection,
    OmLabeledValueSummary,
    OmCheckBox,
    OmFormError,
    OmEfpConfirmationScreen,
    OmModalLoader,
    OmIcon,
} from '@om/component-library-react';
import { getDocumentsForm } from '@om/redux/selectors/digiLifeLoanProtection/uploadDocuments';
import { API_CALL_STATUS, TOGGLE_STATUS } from '@om/redux/reducers/status';
import { transitionScrolling } from '@om/redux/actions/digiLifeLoanProtection/scrolling';
import ServerErrorResponseModal from '../paymentDetailsPage/popups/ServerErrorResponseModal';

const ConfirmDetailsPage = () => {
    const dispatch = useDispatch();

    const selectors = useSelector(
        createStructuredSelector({
            //payment details
            formattedBankName: getFormattedBankName,
            formattedAccountType: getFormattedAccountName,
            accountNumber: getAccountNumber,
            debitOrderDay: getDebitOrderDay,
            paymentAuthorization: getPaymentAuthorization,
            paymentFrequency:getPaymentFrequency,
            accountHolder:getAccountHolder,
            //personal details
            title: getTitle,
            firstNames: getFirstNames,
            surname: getSurname,
            fullName: getFullName,
            gender:getGender,
            idNumber: getId,
            idExpiry:getIdExpiry,
            education:getEducation,
            email: getEmail,
            mobileNumber: getMobile,
            postalAddressSame: getPostalAddressSame,
            addressLabel: getAddressLabel,
            formattedContactNumber: getFormattedContactNumber,
            //address details
            streetAddress: getStreetAddress,
            resedentialStreetAddress: getResedentialStreetAddress,
            //postal address
            postallAddressMethod: getPostalAddressMethod,
            postallAddress: getPostalAddress,
            //beneficiaries
            declarationsChecked: getDeclarationsValid,
            declarationsInvalid: getDeclarationsInvalid,
            declarationErrorMessage: getDeclarationsError,
            isConfirmationPage: getIsConfirmationPage,
            loanAmount:getLoanAmount,
            loanType:getLoanType,
            loanProvider:getBank,
            loanTerm:getLoanTerm,
            employmentType:getEmploymentType,
            occupation:getOccupation,
            sourceOfIncome:getSourceOfIncome,
            premiumAmount:getDigiLifePremiumAmount,
            dateOfBirth:getDateOfBirth,
            cashBack:getCashBack,
            uploadDocuments:getDocumentsForm,
            submissionApiStatus:getSubmissionApiStatus
        })
    );
    return (
        <OmEfpConfirmationScreen>
            <h4 slot="heading" className="no-top-margin no-bottom-margin">
        Lastly, <strong>please confirm your details</strong>
            </h4>

            <ApplicationSummaryDetailsCard
                headingText="Product summary"
                slot="summary-card"
            >
                <OmApplicationReviewSection titleHeader="Plan Details">
                    <h6 className="no-top-margin no-bottom-margin total-heading">
            Plan Details
                    </h6>
                </OmApplicationReviewSection>
       
                <OmLabeledValueSummary
                    is-sensitive="true"
                    label="Plan Name"
                    value={'DigiLife Loan Protection'}
                />
                <OmLabeledValueSummary
                    is-sensitive="true"
                    label="Premium Contribution"
                    value={selectors?.premiumAmount}
                />
                {/* <br/> */}
                <OmLabeledValueSummary
                    is-sensitive="true"
                    label="Payment Frequency"
                    value={selectors?.paymentFrequency.displayValue}
                />
            </ApplicationSummaryDetailsCard>

            <div slot="form">
                <ApplicationSummaryDetailsCard>
                    <OmApplicationReviewSection titleHeader="Personal Details">
                        <span
                            slot="edit-link"
                            onClick={() => dispatch(navigateToPersonalDetails())}
                        >
             
                            <OmIcon iconName="create_edit" />

                        </span>
                    </OmApplicationReviewSection>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            label="Title"
                            value={selectors.title?.displayValue}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Full name"
                            value={selectors?.fullName ?? '-'}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Gender"
                            value={selectors.gender?.displayValue}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Date Of Birth"
                            value={selectors.dateOfBirth?.value}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="ID number"
                            value={selectors.idNumber?.value}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="ID Expiry"
                            value={selectors.idExpiry?.value}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Highest level of education"
                            value={selectors.education?.displayValue}
                        />
                    </div>
                </ApplicationSummaryDetailsCard>
                <ApplicationSummaryDetailsCard>
                    <OmApplicationReviewSection titleHeader="Contact Details">
                        <span
                            slot="edit-link"
                            onClick={() => {
                                dispatch(navigateToPersonalDetails())
                                dispatch(transitionScrolling.scrollToId('email'))}}
                        >
                            <OmIcon iconName="create_edit" />
                        </span>
                    </OmApplicationReviewSection>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Mobile number"
                            value={selectors?.formattedContactNumber}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Email"
                            value={selectors.email?.value}
                        />
                    </div>
                </ApplicationSummaryDetailsCard>
                <ApplicationSummaryDetailsCard>
                    <OmApplicationReviewSection titleHeader="Address Details">
                        <span
                            slot="edit-link"
                            onClick={() => {
                                dispatch(navigateToPersonalDetails())
                                dispatch(transitionScrolling.scrollToId('city'))
                            }}
                        >
                            <OmIcon iconName="create_edit" />
                        </span>
                    </OmApplicationReviewSection>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Residential address"
                            value={selectors?.resedentialStreetAddress}
                        />

                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Postal address"
                            value={selectors?.postallAddress}
                        />
                    </div>
                </ApplicationSummaryDetailsCard>

                <ApplicationSummaryDetailsCard>
                    <OmApplicationReviewSection titleHeader="Loan Details">
                        <span
                            slot="edit-link"
                            onClick={() => {dispatch(navigateToPersonalDetails())
                                dispatch(transitionScrolling.scrollToId('loanAmount'))}}
                        >
                            <OmIcon iconName="create_edit" />

                        </span>
                    </OmApplicationReviewSection>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            label="Loan amount"
                            value={selectors.loanAmount.value}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Loan Type"
                            value={selectors?.loanType.displayValue}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Loan Provider"
                            value={selectors.loanProvider?.displayValue}
                        />
                        <OmLabeledValueSummary
                            label="Loan term"
                            value={selectors?.loanTerm?.value}
                        />
                    </div>
                </ApplicationSummaryDetailsCard>

                <ApplicationSummaryDetailsCard>
                    <OmApplicationReviewSection titleHeader="Employment & Income Details ">
                        <span
                            slot="edit-link"
                            onClick={() => {dispatch(navigateToPersonalDetails())
                                dispatch(transitionScrolling.scrollToId('employmentType'))}}
              
                        >
                            <OmIcon iconName="create_edit" />
                        </span>
                    </OmApplicationReviewSection>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Employment Type"
                            value={selectors?.employmentType?.displayValue}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Occupation"
                            value={selectors.occupation?.value}
                        />
                        <OmLabeledValueSummary
                            label="Source of income"
                            value={selectors?.sourceOfIncome?.displayValue}
                        />
                    </div>
                </ApplicationSummaryDetailsCard>
                <ApplicationSummaryDetailsCard>
                    <OmApplicationReviewSection titleHeader="Benefits Details ">
                    </OmApplicationReviewSection>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Cashback"
                            value={selectors?.cashBack.value==='01'?'Yes':'NO'}
                        />
                    </div>
                </ApplicationSummaryDetailsCard>

                <ApplicationSummaryDetailsCard>
                    <OmApplicationReviewSection titleHeader="Payment Details">
                        <span
                            slot="edit-link"
                            onClick={() => dispatch(navigateToPaymentDetails())}
                        >
                            <OmIcon iconName="create_edit" />

                        </span>
                    </OmApplicationReviewSection>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            label="Bank name"
                            value={selectors?.formattedBankName}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Account holder name"
                            value={selectors?.accountHolder.value}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Account number"
                            value={selectors?.accountNumber?.value}
                        />
                        <OmLabeledValueSummary
                            label="Account type"
                            value={selectors?.formattedAccountType}
                        />
                        <OmLabeledValueSummary
                            label="Debit order date"
                            value={selectors?.debitOrderDay.value + ' of every month'}
                        />
                    </div>
                </ApplicationSummaryDetailsCard>

                <ApplicationSummaryDetailsCard>
                    <OmApplicationReviewSection titleHeader="Documents">
                        <span
                            slot="edit-link"
                            onClick={() => dispatch(navigateToUploadDocuments())}
                        >
                            <OmIcon iconName="create_edit" />

                        </span>
                    </OmApplicationReviewSection>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            label="Certified copy of ID"
                            value={selectors?.uploadDocuments.certifiedCopyOfId.fileName}
                        />
                        { selectors?.uploadDocuments.incomeProof.fileName&& <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Proof of income statement"
                            value={selectors?.uploadDocuments.incomeProof.fileName}
                        />}
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Proof of address"
                            value={selectors?.uploadDocuments.addressProof.fileName}
                        />
                        <OmLabeledValueSummary
                            label="Loan letter"
                            value={selectors?.uploadDocuments.loanLetter.fileName}
                        />
                        <OmLabeledValueSummary
                            label="Bank Statement"
                            value={selectors?.uploadDocuments.bankStatement.fileName}
                        />
                    </div>
                </ApplicationSummaryDetailsCard>
                <OmModalLoader
                    slot="loading-modal"
                    loaderText="Submitting"
                    verticalAlignMiddle="true"
                    open={selectors.submissionApiStatus===API_CALL_STATUS.PENDING}
                />
                <ServerErrorResponseModal
                    slot="server-error-modal"
                    open={selectors.submissionApiStatus===API_CALL_STATUS.FAILURE}
                    buttonHandler={()=>{
                        dispatch(sendConfirmDetailsSubmitApplicationApiStatus(API_CALL_STATUS.IDLE));
                    }}
                />
                <div className="check-box-container" id="declaration">
                    <OmCheckBox
                        checked={selectors?.declarationsChecked}
                        onOnCheckBoxChanged={() => dispatch(toggleDeclarations(selectors?.declarationsChecked?TOGGLE_STATUS.NO:TOGGLE_STATUS.YES))}
                    >
                        <p slot="text-slot">
              I accept the&nbsp;
                            <button onClick={() =>window.open(
                                'https://www.oldmutual.co.bw/v3/assets/blta37b98ee289f937f/blt24577e6d0ebffbb7/61af58c6194977690609993e/OMA_Botswana_DigiLife_terms_and_conditions.pdf',
                                '_blank' 
                            )}>
                terms & conditions.
                            </button>
                        </p>
                    </OmCheckBox>
                    <OmFormError
                        message={selectors?.declarationErrorMessage}
                    ></OmFormError>
                </div>
            </div>
      
        </OmEfpConfirmationScreen>
    );
};

export default ConfirmDetailsPage;
