import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { navigate } from 'gatsby';

import {
    OmFormInputFieldWrapper,
    OmCheckBox,
    OmFormDropdownFieldWrapper,
    OmModalLoader,
    OmEfpPaymentDetails,
    OmFormError,
} from '@om/component-library-react';

import ErrorResponseModal from './popups/ErrorResponseModal';
import SuccessModal from './popups/SuccessModal';
import ServerErrorResponseModal from './popups/ServerErrorResponseModal';
import {
    setBankNameValue,
    setAccountNumberValue,
    setAccountTypeValue,
    setDebitOrderDayValue,
    setAccountValidationReset,
    setBranchCodeValue,
    setPaymentAuthorizationValue,
    setAccountHolderValue,
} from '@om/redux/actions/digiLifeLoanProtection/paymentDetails';
import {
    getFirstNames,
    getSurname,
} from '@om/redux/selectors/digiLifeLoanProtection/personalDetails';
import {
    getBankName,
    getBranchCode,
    getAccountNumber,
    getAccountType,
    getDebitOrderDay,
    getPaymentAuthorizationValid,
    getPaymentAuthorizationInvalid,
    getPaymentDetailsCompleted,
    getPaymentAuthorization,
    getAccountValidationStatus,
    getDisplaySuccessModal,
    getAccountHolder,
} from '@om/redux/selectors/digiLifeLoanProtection/paymentDetails';
import {
    getReferenceBankData,
    getReferenceAccountTypes,
    getReferenceBranchCodesData,
    getReferenceDebitDay,
    getReferenceDataApiStatus,
} from '@om/redux/selectors/digiLifeLoanProtection/referenceData';
// import { getReferenceBankData,getReferenceBranchCodesData } from "@om/redux/selectors/digiLifeLoanProtection/referenceData";
import { getTotalPremium } from '@om/redux/selectors/easiplusFuneralPlan/livesCovered';
import {
    PRODUCT_DETAIL_URL,
    ACCOUNT_VALIDATION_STATUS,
} from '@om/redux/actions/digiLifeLoanProtection/types';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import { navigateNext } from '@om/redux/actions/digiLifeLoanProtection/routing';

import {
    getDeclarationsValid,
    getDeclarationsInvalid,
    getDeclarationsError,
} from '@om/redux/selectors/easiplusFuneralPlan/confirmDetails';
import { getBranchCodeReferenceData } from '@om/redux/actions/digiLifeLoanProtection/referenceData';
import { API_CALL_STATUS, TOGGLE_STATUS } from '@om/redux/reducers/status';


const PaymentDetailsPage = ({ includeAvs, includeCdv }) => {
    const dispatch = useDispatch();
    const [shouldFetchBranchCodes,setShouldFetchBranchCodes]=useState(false)
    const [isAccountHolderNameEdited,setIsAccountHolderNameEdited]=useState(true)
    const {
        name,
        surname,
        bankName,
        branchCode,
        accountNumber,
        accountType,
        debitOrderDay,
        paymentAuthorization,
        referenceBanks,
        referenceAccountTypes,
        referenceDebitOrderDates,
        accountValidationStatus,
        displaySuccessModal,
        referenceBranchCode,
        referenceDataApiStatus,
        accountHolder,
    } = useSelector(
        createStructuredSelector({
            paymentAuthorizationValid: getPaymentAuthorizationValid,
            paymentAuthorizationInvalid: getPaymentAuthorizationInvalid,
            name: getFirstNames,
            surname: getSurname,
            bankName: getBankName,
            branchCode:getBranchCode,
            accountNumber: getAccountNumber,
            accountHolder:getAccountHolder,
            accountType: getAccountType,
            debitOrderDay: getDebitOrderDay,
            paymentAuthorization: getPaymentAuthorization,
            referenceBanks: getReferenceBankData,
            referenceAccountTypes: getReferenceAccountTypes,
            referenceDebitOrderDates: getReferenceDebitDay,
            accountValidationStatus: getAccountValidationStatus,
            referenceBranchCode: getReferenceBranchCodesData,
            paymentDetailsCompleted: getPaymentDetailsCompleted,

            declarationsChecked: getDeclarationsValid,
            declarationsInvalid: getDeclarationsInvalid,
            declarationErrorMessage: getDeclarationsError,

            premium: getTotalPremium,
            displaySuccessModal: getDisplaySuccessModal,

            referenceDataApiStatus:getReferenceDataApiStatus,
        })
    );
    useEffect(() => {
        if (shouldFetchBranchCodes) {
            branchCode.value &&
        dispatch(setBranchCodeValue({ id: null, value: null }));
            bankName.value &&
        dispatch(getBranchCodeReferenceData(`${bankName.value}`));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankName.value, shouldFetchBranchCodes]);

    useEffect(()=>{
        isAccountHolderNameEdited && !accountHolder.value && dispatch(setAccountHolderValue((name.value??'') +' '+ (surname.value??'')));
        setIsAccountHolderNameEdited(false);
    },[isAccountHolderNameEdited])
    return (
        <OmEfpPaymentDetails>
            <h5 slot="heading" className="heading" id="heading-anchor">
      We need your payment details to finalise your policy
            </h5>
     
            <h6 slot="subheading">
                <strong>Banking Details</strong> 
            </h6>
      
            <div slot="form">
                <section className="form-section">
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        required
                        formId="paymentDetailsForm"
                        placeholder="Bank name*"
                        name="bankName"
                        align="left"
                        onOnSelect={(event) =>{ dispatch(setBankNameValue(event.detail))
                            setShouldFetchBranchCodes(true)}}
                        value={bankName.value}
                        state={bankName.status}
                        error-message={bankName.error}
                        options={JSON.stringify(referenceBanks?.map((bank)=>({value:bank.bank_code,label:bank.name})))}
                    />
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        required
                        formId="paymentDetailsForm"
                        placeholder="Branch Code*"
                        name="branchCode"
                        align="left"
                        onOnSelect={(event) => dispatch(setBranchCodeValue(event.detail))}
                        value={branchCode.value}
                        state={branchCode.status}
                        error-message={branchCode.error}
                        options={JSON.stringify(referenceBranchCode?.map((bank)=>({value:bank.id,label:bank.name+' - '+bank.branch_code})))}
                    />

                    <OmFormInputFieldWrapper
                        isSensitive
                        required
                        id="accountHolderName"
                        size="full"
                        align="left"
                        placeholder="Account holder name*"
                        form-id="paymentDetailsForm"
                        name="accountHolderName"
                        error-message={accountHolder.error}
                        state={accountHolder.status}
                        value={accountHolder.value}
                        onPersist={(event) => {
                            dispatch(setAccountHolderValue(event.detail))
                        }}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        required
                        id="accountNumber"
                        size="full"
                        align="left"
                        mask="number"
                        placeholder="Account number*"
                        form-id="paymentDetailsForm"
                        name="accountNumber"
                        keyboard-mode="numeric"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={accountNumber.value}
                        error-message={accountNumber.error}
                        state={accountNumber.status}
                        onPersist={(event) => {
                            if (event.detail !== accountNumber.value) {
                                dispatch(setAccountNumberValue(event.detail));
                            }
                        }}
                    />
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        required
                        formId="paymentDetailsForm"
                        placeholder="Account type*"
                        name="accountType"
                        align="left"
                        onOnSelect={(event) =>
                            dispatch(setAccountTypeValue(event.detail))
                        }
                        value={accountType.value}
                        error-message={accountType.error}
                        state={accountType.status}
                        options={JSON.stringify(referenceAccountTypes?.map((account)=>({value:account.code,label:account.description})))}
                    />
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        required
                        formId="paymentDetailsForm"
                        placeholder="Select a day for your debit order*"
                        name="debitOrderDay"
                        align="left"
                        onOnSelect={(event) =>
                            dispatch(setDebitOrderDayValue(event.detail.value))
                        }
                        value={debitOrderDay.value}
                        error-message={debitOrderDay.error}
                        state={debitOrderDay.status}
                        options={
                            referenceDebitOrderDates &&
              JSON.stringify(
                  referenceDebitOrderDates.map((option) => ({
                      value: option.code,
                      label: option.description,
                  }))
              )
                        }
                    />
                    <div className="check-box-container" id="paymentAuthorization">
                        <OmCheckBox
                            text="I hereby confirm that the above-provided banking details are mine. I understand that my application will be declined if the provided banking details are not mine."
                            checked={paymentAuthorization.value}
                            value={paymentAuthorization.value}
                            onOnCheckBoxChanged={() => {
                                dispatch(setPaymentAuthorizationValue(paymentAuthorization.value===TOGGLE_STATUS.NO?TOGGLE_STATUS.YES:TOGGLE_STATUS.NO));
                            }}
                        />
                        <OmFormError
                            message={
                                paymentAuthorization.error
                                    ? 'Authorization required to continue.'
                                    : ''
                            }
                        ></OmFormError>
                    </div>
                    <div style={{height:150}}>

                    </div>
         
                </section>
            </div>

            <OmModalLoader
                slot="loading-modal"
                loaderText="Loading branch codes"
                verticalAlignMiddle="true"
                open={referenceDataApiStatus===API_CALL_STATUS.PENDING}
            />

            <ErrorResponseModal
                slot="error-modal"
                open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.FAILED}
                buttonHandler={() => dispatch(setAccountValidationReset())}
            />

            <SuccessModal
                slot="good-to-go-modal"
                open={displaySuccessModal}
                buttonHandler={() =>
                    dispatch(navigateNext())
                }
            />

            <ServerErrorResponseModal
                slot="server-error-modal"
                open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.ERROR}
                buttonHandler={() => {
                    dispatch(setAccountValidationReset());
                    navigate(PRODUCT_DETAIL_URL);
                }}
            />
        </OmEfpPaymentDetails>
    );
};

export default PaymentDetailsPage;
