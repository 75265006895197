import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, navigate } from 'gatsby';

import OmButton from '../../components/forms/OmButton';
import {
    Om1ColLayout,
    OmIcon,
    OmApplicationWizardHeader,
    OmApplicationProgressBar,
} from '@om/component-library-react';
import { resetPersonalDetails } from '@om/redux/actions/digiLifeLoanProtection/personalDetails';
import { resetPaymentDetails } from '@om/redux/actions/digiLifeLoanProtection/paymentDetails';
import { resetConfirmDetails } from '@om/redux/actions/digiLifeLoanProtection/confirmDetails';
import { resetScroll } from '@om/redux/actions/digiLifeLoanProtection/scrolling';
import { resetRouting } from '@om/redux/actions/digiLifeLoanProtection/routing';
import {
    getHideDesktop,
    getHideMobile,
    getProgressBarIndex,
} from '@om/redux/selectors/digiLifeLoanProtection/routing';
import { getSkipConfirmationPage } from '@om/redux/selectors/easiplusFuneralPlan/confirmDetails';
import { PRODUCT_DETAIL_URL } from '@om/redux/actions/digiLifeLoanProtection/types';
import { setLeadSystem, setSideBarOpen } from '@om/redux/actions/publicWeb/callMeBack';
import { getDigiLifeCMDObject } from '@om/redux/selectors/digiLifeLoanProtection/personalDetails';

const progressBarSteps = (shouldSkipConfirmationPage) => {
    if (shouldSkipConfirmationPage) {
        return ['Personal Details', 'Payment Details', 'Upload Documents'].join(
            '|'
        );
    }
    return [
        'Personal Details',
        'Payment Details',
        'Upload Documents',
        'Confirmation',
    ].join('|');
};

const ApplicationHeader = ({ mobileHeaderTexture }) => {
    const dispatch = useDispatch();
    const {
        hideDesktop,
        hideMobile,
        progressBarIndex,
        skipConfirmationPage,
        dlpCmdObject,
    } = useSelector(
        createStructuredSelector({
            hideDesktop: getHideDesktop,
            hideMobile: getHideMobile,
            progressBarIndex: getProgressBarIndex,
            skipConfirmationPage: getSkipConfirmationPage,
            dlpCmdObject: getDigiLifeCMDObject
        })
    );

    const closeButtonClick = () => {
        navigate(PRODUCT_DETAIL_URL);
        dispatch(resetPersonalDetails());
        dispatch(resetPaymentDetails());
        dispatch(resetConfirmDetails());
        dispatch(resetScroll());
        dispatch(resetRouting());
    };
    function openCallMeBackForm() {
        dispatch(setSideBarOpen(true));
        dispatch(setLeadSystem('bw'));
    }

    return (
        <OmApplicationWizardHeader
            helpNumber="3995701"
            headerTexture={
                mobileHeaderTexture ? mobileHeaderTexture.mobileHeaderTexture.url : ''
            }
        >
            <div slot="wizard-title">
                <h5>
                    <strong>DigiLife Loan Protection</strong>
                </h5>
            </div>

            <OmButton
                slot="contact-button"
                type="secondary"
                size="small"
                text="Call me back"
                actionObject={dlpCmdObject}
                onClick={() =>  openCallMeBackForm()}
            />

            <Link slot="close-button" to={PRODUCT_DETAIL_URL}>
                <OmIcon
                    className="close-icon"
                    iconName="close"
                    onClick={() => closeButtonClick()}
                />
            </Link>

            <div slot="progress-bar">
                <Om1ColLayout className="header">
                    <OmApplicationProgressBar
                        applicationName="DigiLife Loan Protection"
                        steps={progressBarSteps(skipConfirmationPage)}
                        index={progressBarIndex}
                        closable={true}
                        hideDesktop={hideDesktop}
                        hideMobile={hideMobile}
                    >
                        <OmButton
                            actionType="Default"
                            slot="close-application"
                            type="text"
                            size="small"
                            text="CLOSE"
                            onClick={() => closeButtonClick()}
                        />
                    </OmApplicationProgressBar>
                </Om1ColLayout>
            </div>
        </OmApplicationWizardHeader>
    );
};

export default ApplicationHeader;
