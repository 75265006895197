import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setSkipConfirmationPageValue } from '@om/redux/actions/easiplusFuneralPlan/confirmDetails';


import {
    OmEfpReplacementPolicy,
} from '@om/component-library-react';

const ReplacementPolicyPage = ({ excludeConfirmDetailsPage }) => {
    const dispatch = useDispatch();
    
    // if exclude_confirm_details_page is set to true, dispatch setSkipConfirmationPageValue with true
    useEffect(() => {
        if (excludeConfirmDetailsPage) {
            dispatch(setSkipConfirmationPageValue(true));
        }
    });

    return (
        <OmEfpReplacementPolicy>
            <h3 slot="heading" className="no-top-margin no-bottom-margin">
        Before we continue
            </h3>
            <p slot="subheading">
        Please have the <strong> following documents </strong>ready for upload:
            </p>
            <div slot="form">
                <section className="form-section">
                    {/* <OmApplicationCard> */}
                    <div slot="card-content">
                        <h6 className="medium no-top-margin no-bottom-margin">Employed</h6>
                        <p>
                            <small>
                1. Certified Copy of ID (jpeg, png or pdf) 2MB <br />
                2. Proof of address (letter from employer/affidavit/latest utility bill)(jpeg, png or pdf) 2MB
                                <br />
                3. Loan letter/proof of active loan (jpeg, png or pdf) 2MB
                                <br />
                4. Proof of income (letter from employer/latest payslip) (jpeg, png or pdf) 2MB
                                <br />
                5. Confirmation of bank account ( bank statement/bank confirmation letter) (jpeg, png or pdf) 2MB
                                <br />
                            </small>
                        </p>
                        <br />
                        <h6 className="medium no-top-margin no-bottom-margin">
              Self Employed
                        </h6>
                        <p>
                            <small>
                1. Certified Copy of ID (jpeg, png or pdf) 2MB
                                <br />
                2. Proof of address (affidavit/latest utility bill)(jpeg, png or pdf) 2MB
                                <br />
                3. Loan letter/proof of active loan (jpeg, png or pdf) 2MB
                                <br />
                4. Confirmation of bank account ( bank statement/bank confirmation letter) (jpeg, png or pdf) 2MB
                                <br />
                            </small>
                        </p>
                    </div>
                    {/* </OmApplicationCard> */}
                    <span id="continue-button-anchor" />
                </section>
            </div>
        </OmEfpReplacementPolicy>
    );
};

export default ReplacementPolicyPage;
